import { isDebugMode, getQueryParams, windowExists, isCareAgent, isProd } from "@internal/utils-browser";
import { getUserSessionId, getStudioUniqueSessionId } from "@internal/utils-tracking";
import { Newrelic, SimpleType } from "./types";
import { type NewrelicError } from "./errors";

function cleanseAttributeName(input: string) {
    // allowable characters retrieved from
    // https://docs.newrelic.com/docs/insights/insights-data-sources/custom-data/insights-custom-data-requirements-limits#general
    return input.replace(/[^a-zA-Z0-9_.:]/g, "_");
}

export class NewRelicWrapper implements Omit<Newrelic, "wrapLogger"> {
    agent: Newrelic;
    customAttributes: Record<string, SimpleType> = {};

    constructor(agent: Newrelic) {
        this.agent = agent;

        if (windowExists()) {
            // set team tag to Design Editing, this should route logs to correct partiion
            this.setCustomAttribute("vista.team_tag", "DOM-0170");
            this.setCustomAttribute("deployment.environment.name", isProd() ? "production" : "development");

            this.setCustomAttribute("metadata/href", window.location.href);
            this.setCustomAttribute("isDebugMode", `${isDebugMode()}`);

            const queryParams = getQueryParams();
            Object.keys(queryParams).forEach(param => {
                if (param !== "selectedOptions" && param !== "_gl" && param !== "metadata") {
                    this.setCustomAttribute(`param/${cleanseAttributeName(param)}`, queryParams[param]);
                }
            });

            if (window.QuantumMetricAPI && window.QuantumMetricAPI.getReplay) {
                this.setQMReplayLink();
            } else {
                // leaving this, but I believe it may be overwritten by the integration for Vista Segment
                window.QuantumMetricOnload = () => {
                    if (window.QuantumMetricAPI && window.QuantumMetricAPI.getReplay) {
                        this.setQMReplayLink();
                    }
                };
            }

            // setup some attributes for logs, NR logs this information for its own events
            // but the logs include minimal information
            if (window.document.referrer) {
                this.customAttributes["referrerUrl"] = window.document.referrer;
            }
            // possible attributes to add:
            // browser height/width
            // device type, user agent name/os/version
            // timeSinceLoad - can't add this since it would differ for each log

            // setup logger wrapping
            // it's not obvious but changes to the customAttributes object will be reflected in the logs
            this.agent.wrapLogger(console, "error", { level: "error", customAttributes: this.customAttributes });
        }
    }

    setCustomAttribute(name: string, value: string | number | boolean | null): void {
        // trying to set undefined values generates console warnings
        if (value !== undefined) {
            this.agent.setCustomAttribute(name, value);
            this.customAttributes[name] = value;
        }
    }

    setUpUserVars(locale: string) {
        this.setCustomAttribute("studioUserSession", getUserSessionId());
        this.setCustomAttribute("studioUniqueSession", getStudioUniqueSessionId());
        this.setCustomAttribute("metadata/locale", locale);
        // try to retrieve replay link in case if wasn't possible originally
        this.setQMReplayLink();
    }

    setLoadFinished() {
        this.setCustomAttribute("loadingFinished", "true");
        // try to retrieve replay link in case if wasn't possible originally
        this.setQMReplayLink();
    }

    setDecorationTechnology(decorationTechnology?: string) {
        this.setCustomAttribute("decorationTechnology", decorationTechnology ?? null);
    }

    updateCustomAttributes(state: Record<string, any>) {
        try {
            if (state.productKey) {
                this.setCustomAttribute(`param/key`, state.productKey);
            }
            if (state.productVersion) {
                this.setCustomAttribute(`param/productVersion`, state.productVersion);
            }
            if (state.workId) {
                this.setCustomAttribute(`param/workId`, state.workId);
            }
            if (state.mpvId) {
                this.setCustomAttribute(`param/mpvId`, state.mpvId);
            }
            this.setCustomAttribute(`isCareAgent`, `${isCareAgent()}`);
            // eslint-disable-next-line no-empty -- @todo https://vistaprint.atlassian.net/browse/AST-2426
        } catch {}
    }

    addRelease(releaseId: string) {
        this.agent.addRelease("studio", releaseId);
    }

    setApplicationVersion(releaseId: string) {
        this.agent.setApplicationVersion(releaseId);
    }

    addPageAction(eventName: string, includedMetadata?: Record<string, any>) {
        this.setQMReplayLink();

        this.agent.addPageAction(eventName, this.augmentMetadata(includedMetadata));
    }

    newTimingEvent(eventName: string, includedMetadata?: Record<string, any>) {
        let startTime: number;
        return {
            start: () => {
                startTime = performance.now();
            },
            end: () => {
                const duration = (performance.now() - startTime) / 1000;

                this.agent.addPageAction(eventName, {
                    ...includedMetadata,
                    duration
                });
            },
            status: () => {
                return performance.now() - startTime;
            }
        };
    }

    noticeError(error: NewrelicError | Error | string, includedMetadata?: Record<string, any>) {
        this.setQMReplayLink();

        this.agent.noticeError(error, this.augmentMetadata(includedMetadata));
    }

    log(
        message: string,
        options?: {
            // defaults to 'warn'
            level?: "debug" | "error" | "info" | "trace" | "warn";
            customAttributes?: Record<string, SimpleType>;
        }
    ) {
        this.setQMReplayLink();

        this.agent.log(message, {
            level: options?.level,
            customAttributes: {
                ...options?.customAttributes,
                ...this.customAttributes,
                timeSinceload: performance.now()
            }
        });
    }

    private augmentMetadata(metadata?: Record<string, any>) {
        return {
            ...metadata,
            // eslint-disable-next-line compat/compat -- lack of support is fine, we just won't log this
            usedJSHeapSize: window.performance?.memory?.usedJSHeapSize,
            // eslint-disable-next-line compat/compat -- lack of support is fine, we just won't log this
            jsHeapSizeLimit: window.performance?.memory?.jsHeapSizeLimit
        };
    }

    private setQMReplayLink() {
        if (windowExists()) {
            if (window.QuantumMetricAPI && window.QuantumMetricAPI.getReplay) {
                this.setCustomAttribute("QMReplayLink", window.QuantumMetricAPI.getReplay());
            }
        }
    }
}

export const getNewrelicAgent = () =>
    windowExists() && typeof window.newrelic !== "undefined"
        ? window.newrelic
        : {
              addPageAction() {},
              addRelease() {},
              setApplicationVersion() {},
              noticeError() {},
              setCustomAttribute() {},
              wrapLogger() {},
              log() {}
          };

/*
 * @deprecated Use the new {@link ScopedNewrelicWrapper} instead.
 */
export const newRelicWrapper = new NewRelicWrapper(getNewrelicAgent());
